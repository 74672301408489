import Vue from "vue";
import Vuex from "vuex";
import Leads from "./modules/Leads";
Vue.use(Vuex);
export default new Vuex.Store({
  state: {
  },
  getters: {
  },
  actions: {
  },
  mutations: {
  },
  modules: {
    Leads
  },
});