<template>
  <div class="">
    <b-container fluid="xl">
      <div class="px-xl-5 px-md-2 mx-lg-2">
        <b-row cols-md="2" cols="1">
          <b-col class="text-start pe-lg-5 pe-1"
            ><div>
              <img
                src="../assets/catalogue.png"
                class="mt-md-n5 book-img mx-auto text-center d-flex"
              />
              <h1 class="display-4 fw-stronger">
                Veiliger wonen in uw eigen huis
              </h1>
              <h5 class="l-height">
                Als u meer moeite hebt met traplopen, kan een traplift een
                uitkomst zijn. U wordt veilig en comfortabel naar boven en
                beneden gebracht. In onze gratis traplift brochure kunt u op uw
                gemak alles lezen over trapliften. Met deze brochure krijgt u
                een beeld van de mogelijkheden en modellen, maar ook de aankoop
                en financiering.
              </h5>
              <h5 class="l-height fw-stronger">
                Vraag onze brochure aan en ontdek:
              </h5>
              <ol>
                <li class="h5 l-height mb-0">Tips en adviezen</li>
                <li class="h5 l-height mb-0">
                  Grootste assortiment in merken en modellen
                </li>
                <li class="h5 l-height mb-0">
                  Trapliften in iedere prijsklasse
                </li>
                <li class="h5 l-height mb-0">
                  Vul hiernaast uw gegevens in en ontvang de gratis traplift
                  brochure
                </li>
              </ol>
            </div></b-col
          >
          <b-col
            ><h1 class="fw-bold mt-5">
              Vergelijk 17 trapliften van 6 fabrikanten
            </h1>
            <b-button class="s-btn btn-md px-md-5 px-3 fw-bold">
              Vraag de gratis en vrijblijvende brochure aan </b-button
            ><b-card
              ><div>
                <h3>
                  <span class="fw-bold">JA,</span> stuur mij de gratis traplift
                  brochure
                </h3>
                <b-form
                  @submit.prevent="onSubmit"
                  v-if="show"
                  class="text-start"
                >
                  <b-form-group
                    id="input-group-1"
                    label="Aanhef:"
                    label-for="input-1"
                  >
                    <b-form-select
                      id="input-1"
                      v-model="form.gender"
                      :options="gender"
                      required
                      class="form-control"
                    ></b-form-select>
                  </b-form-group>
                  <b-form-group
                    id="input-group-2"
                    label="Voornaam:"
                    label-for="input-2"
                  >
                    <b-form-input
                      id="input-2"
                      :state="fNameValidation"
                      v-model="form.firstname"
                      placeholder="Bv. Lore"
                      required
                    ></b-form-input>
                    <b-form-invalid-feedback :state="fNameValidation">
                      Alleen letters zijn toegestaan.
                    </b-form-invalid-feedback>
                  </b-form-group>
                  <b-form-group
                    id="input-group-3"
                    label="Achternaam:"
                    label-for="input-3"
                  >
                    <b-form-input
                      id="input-3"
                      :state="lNameValidation"
                      v-model="form.lastname"
                      placeholder="Bv. Peeters"
                      required
                    ></b-form-input>
                    <b-form-invalid-feedback :state="lNameValidation">
                      Alleen letters zijn toegestaan.
                    </b-form-invalid-feedback>
                  </b-form-group>
                  <b-form-group
                    id="input-group-4"
                    label="Telefoonnummer:"
                    label-for="input-4"
                  >
                    <b-form-input
                      id="input-4"
                      :state="phoneValidation"
                      v-model="form.phone_number"
                      type="tel"
                      placeholder="Bv. 0471123456"
                      maxlength=""
                      required
                    ></b-form-input>
                    <b-form-invalid-feedback :state="phoneValidation">
                      Telefoonnummer niet correct
                    </b-form-invalid-feedback>
                  </b-form-group>
                  <b-form-group
                    id="input-group-5"
                    label="E-mail:"
                    label-for="input-5"
                  >
                    <b-form-input
                      :state="emailValidation"
                      id="input-5"
                      v-model="form.email"
                      type="email"
                      placeholder="Bv. lore.peeters@gmail.com"
                      required
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group
                    id="input-group-6"
                    v-slot="{ ariaDescribedby }"
                    class="py-3"
                  >
                    <b-form-checkbox-group
                      v-model="form.answers"
                      id="checkboxes-6"
                      :aria-describedby="ariaDescribedby"
                      required
                    >
                      <b-form-checkbox
                        value="3212"
                        required
                        class="d-flex align-items-baseline"
                        ><h6 class="ms-3">
                          Ja, ik wil graag deze informatie ontvangen en Mobilae
                          mag mij per e-mail en/of telefoon contacteren. Ik ga
                          akkoord met de voorwaarden. *
                        </h6></b-form-checkbox
                      >
                    </b-form-checkbox-group>
                  </b-form-group>

                  <b-button
                    type="submit"
                    variant="primary"
                    class="s-btn btn-lg fw-bold"
                    :disabled="getLoader"
                    ><b-spinner v-if="getLoader" class="text-center" /><span
                      v-else
                      >BROCHURE AANVRAGEN >></span
                    >
                  </b-button>
                </b-form>
              </div>
            </b-card></b-col
          >
        </b-row>
      </div>
    </b-container>
    <div class="hero bg-color py-4 py-md-5 mt-4">
      <b-container fluid="xl">
        <div class="px-xl-5 px-md-2 mx-lg-2">
          <b-row cols-md="2" cols="1" class="align-items-center">
            <b-col class="text-start"
              ><div>
                <h1 class="fw-stronger">
                  Vind de geschikte traplift voor uw situatie
                </h1>
                <h5 class="l-height">
                  Wilt u weer gemakkelijk naar boven of beneden? Met een Mobilae
                  traplift blijft u mobiel, veilig en zelfstandig in huis. Als
                  onafhankelijk adviseur aan uw zijde helpen wij u bij het
                  kiezen van de juiste traplift. Ons uitgebreide assortiment
                  bevat uitsluitend hoogwaardige modellen van topfabrikanten.
                  Samen met u lopen we de door verschillende mogelijkheden. We
                  bekijken welk model van welke fabrikant het beste past bij uw
                  persoonlijke woonsituatie, uw wensen en behoeften. Met 30 jaar
                  ervaring in heel Europa zorgen we ervoor dat elke stap op weg
                  naar uw nieuwe traplift eenvoudig en goed doordacht is. Van
                  advies tot installatie en klantenservice. Onze unieke
                  garanties en jaarlijks onderhoud zorgen ervoor dat u jarenlang
                  tevreden bent.
                </h5>
              </div>
            </b-col>
            <b-col>
              <div>
                <img src="../assets/afspraak.png" class="w-100 rounded" />
              </div>
            </b-col>
          </b-row>
        </div>
      </b-container>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "FormSection",

  data() {
    return {
      validationCheck: false,
      emailErr: true,
      temp_answers: {
        answers: [],
      },
      form: {
        language: "nl_BE",
        publisher_id: "",
        site_subid: "",
        site_custom_url: "https://mobilae.web.app",
        site_custom_name: "Mobilae trapliften",
        ip: "91.180.156.50",
        optin_timestamp: "2022-05-12 14:28:25",
        date_of_birth: "1975-04-08",
        answers: [],
        gender: "null",
        firstname: "",
        lastname: "",
        email: "",
        phone_number: "",
      },
      gender: [
        { text: "Aanhef", value: null, disabled: true },
        { text: "Dhr.", value: "male", disabled: false },
        { text: "Mevrouw.", value: "female", disabled: false },
      ],
      show: true,
    };
  },

  methods: {
    ...mapActions(["postLead"]),
    resetForm() {
      this.form = {
        language: "nl_BE",
        publisher_id: "1",
        site_custom_url: "https://mobilae.web.app",
        site_custom_name: "Mobilae trapliften",
        ip: "91.180.156.50",
        optin_timestamp: "2022-05-12 14:28:25",
        date_of_birth: "1975-04-08",
        answers: [3212],
        gender: "null",
        firstname: "",
        lastname: "",
        email: "",
        phone_number: "",
      };
    },
    async onSubmit() {
      if (this.validationCheck) {
        const phoneNumberWithoutPunctuation = this.form.phone_number.replace(
          /[\s-]/g,
          ""
        );

        let formattedPhoneNumber = phoneNumberWithoutPunctuation;

        if (formattedPhoneNumber.startsWith("0032")) {
          formattedPhoneNumber = `+${formattedPhoneNumber.substring(2)}`;
        } else if (formattedPhoneNumber.startsWith("32")) {
          formattedPhoneNumber = `+${formattedPhoneNumber}`;
        }

        console.log("Formatted phone number:", formattedPhoneNumber);

        // const phoneNumberWithoutPunctuation = this.form.phone_number
        //  .replace(/[\s-]/g, "")
        // .replace(/^00(\d+)$/, "$1");
        this.form.phone_number = formattedPhoneNumber;
        let resp = await this.postLead({
          ...this.form,
          site_subid: this.$route.query.oa_id,
          publisher_id: this.$route.query.site,
          phone_number: formattedPhoneNumber,
        });
        if (resp.success == true) {
          this.resetForm();
          this.$router.push("bedankt");
        }
        console.log(resp);
      }
    },
  },
  computed: {
    ...mapGetters(["getLoader"]),
    fNameValidation() {
      if (this.form.firstname == "") {
        this.validationCheck = false;
        return null;
      }
      let nameRegex = /^[a-zA-Z\s.,'-]{1,30}$/;
      if (this.form.firstname.match(nameRegex)) {
        this.validationCheck = true;
        return true;
      } else {
        this.validationCheck = false;
        return false;
      }
    },
    lNameValidation() {
      if (this.form.lastname == "") {
        this.validationCheck = false;
        return null;
      }
      let nameRegex = /^[a-zA-Z\s.,'-]{1,30}$/;
      if (this.form.lastname.match(nameRegex)) {
        this.validationCheck = true;
        return true;
      } else {
        this.validationCheck = false;
        return false;
      }
    },
    emailValidation() {
      if (this.form.email == "") {
        this.validationCheck = false;
        return null;
      }
      if (
        /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9_.-]+\.[a-zA-Z]{2,}$/.test(this.form.email)
      ) {
        this.validationCheck = true;
        return true;
      } else {
        this.validationCheck = false;
        return false;
      }
    },
    phoneValidation() {
      if (this.form.phone_number == "") {
        this.validationCheck = false;
        return null;
      }
      let phoneRegex = /^(0|\+32|0032|00 32)([-\s]?\d[-\s]?){9}$/;
      if (this.form.phone_number == "0") {
        return true;
      } else if (this.form.phone_number.match(phoneRegex)) {
        this.validationCheck = true;
        return true;
      } else {
        this.validationCheck = false;
        return false;
      }
    },
  },
};
</script>

<style lang="scss">
$enable-negative-margins: true;

.custom-select {
  appearance: revert !important;
}
.form-control {
  padding: 0.75rem 0.75rem !important;
}
.s-btn {
  padding: 1rem !important;
  width: 100%;
  background-color: #ff5a00 !important;
  border: 0 !important;
  border-radius: 15px !important;
  @media (min-width: 767px) and (max-width: 990px) {
    padding: 1rem !important;
  }
}
.book-img {
  width: 100%;
  @media (max-width: 767.9px) {
    width: 50% !important;
  }
}
.card {
  margin: 50px 0;
  border-radius: 10px !important;
}
.card-body {
  background-color: #50a89e !important;
  color: white;
  padding: 50px !important;
  border-radius: 7px;
  @media (max-width: 990px) {
    padding: 30px 15px !important;
  }
}
.opacity {
  opacity: 0.6;
}
label {
  padding: 7px 0;
}
@media (min-width: 768px) {
  .mt-md-n5 {
    margin-top: -3rem !important;
  }
}
.bg-color {
  background-color: #b9e6ff !important;
}
.l-height {
  line-height: 1.9 !important;
}
</style>
