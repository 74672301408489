<template>
  <div class="home">
    <Header />
    <FormSection />
    <!-- <SaveThings /> -->
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import Header from "@/components/HeaderH.vue";
import FormSection from "@/components/FormSection.vue";
// import SaveThings from "@/components/SaveThings.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "Home",
  components: {
    Header,
    FormSection,
    // SaveThings,
    Footer,
  },
  mounted() {
    let useScript = document.createElement("script");
    useScript.setAttribute(
      !(function (f, b, e, v, n, t, s) {
        if (f.fbq) return;
        n = f.fbq = function () {
          n.callMethod
            ? n.callMethod.apply(n, arguments)
            : n.queue.push(arguments);
        };
        if (!f._fbq) f._fbq = n;
        n.push = n;
        n.loaded = !0;
        n.version = "2.0";
        n.queue = [];
        t = b.createElement(e);
        t.async = !0;
        t.src = v;
        s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s);
      })(
        window,
        document,
        "script",
        "https://connect.facebook.net/en_US/fbevents.js"
      ),
      fbq("init", "468081108442050"),
      fbq("track", "PageView")
    );
    document.head.appendChild(useScript);
  },
  created() {
    this.noscriptHtml =
      '<noscript><img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=468081108442050&ev=PageView&noscript=1" /></noscript>';
  },
};
</script>
